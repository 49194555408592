<template>
    <div class="layout pui-welcome-panel">
		<div class="flex">
			<h1 class="pa-3 display-1 text-center grey--text">{{ $t('general.welcome') }}</h1>
			<div class="puiwellcomepanel__logo-img"></div>
			<div class="pa-3 text-center">
				<a href="https://www.prodevelop.es/" target="_blank">Prodevelop SL</a>
			</div>
			<div class="pa-3 text-center">
				<h1 class="pa-1 text-center grey--text caption">© Powered by PUI9</h1>
			</div>
		</div>
	</div>
</template>


<script>
export default {
    name: 'AppWelcomePanel'
};
</script>
